import AmexIcon from './amex.svg'
import CirrusIcon from './cirrus.svg'
import DinersIcon from './diners.svg'
import DiscoverIcon from './discover.svg'
import JcbIcon from './jcb.svg'
import MaestroIcon from './maestro.svg'
import MastercardIcon from './mastercard.svg'
import UnionpayIcon from './unionpay.svg'
import VisaIcon from './visa.svg'

const CardIcons = {
 amex: AmexIcon,
 cirrus: CirrusIcon,
 diners: DinersIcon,
 discover: DiscoverIcon,
 jcb: JcbIcon,
 maestro: MaestroIcon,
 mastercard: MastercardIcon,
 unionpay: UnionpayIcon,
 visa: VisaIcon,
}

export default CardIcons
