import React from 'react'
import styled from 'styled-components'

import Bold from '@zendeskgarden/svg-icons/src/16/bold-fill.svg'
import Italic from '@zendeskgarden/svg-icons/src/16/italic-fill.svg'
import Underline from '@zendeskgarden/svg-icons/src/16/underline-fill.svg'

import AlertFill from '@zendeskgarden/svg-icons/src/16/alert-warning-fill.svg'
import AlertStroke from '@zendeskgarden/svg-icons/src/16/alert-warning-stroke.svg'
import ArrowChange from '@zendeskgarden/svg-icons/src/16/chevron-double-down-stroke.svg'
import ArrowDown from '@zendeskgarden/svg-icons/src/16/chevron-down-fill.svg'
import ArrowLeft from '@zendeskgarden/svg-icons/src/16/chevron-left-stroke.svg'
import ArrowLeftUp from '@zendeskgarden/svg-icons/src/16/arrow-left-up-fill.svg'
import ArrowReverse from '@zendeskgarden/svg-icons/src/16/arrow-reverse-fill.svg'
import ArrowCycle from '@zendeskgarden/svg-icons/src/16/arrow-retweet-fill.svg'
import ArrowRight from '@zendeskgarden/svg-icons/src/16/chevron-right-stroke.svg'
import BackArrow from '@zendeskgarden/svg-icons/src/16/chevron-double-left-stroke.svg'
import BookFill from '@zendeskgarden/svg-icons/src/16/book-open-fill.svg'
import BookStroke from '@zendeskgarden/svg-icons/src/16/book-open-stroke.svg'
import Bullets from '@zendeskgarden/svg-icons/src/16/list-bullet-stroke.svg'
import Calendar from '@zendeskgarden/svg-icons/src/16/calendar-fill.svg'
import Cart from '@zendeskgarden/svg-icons/src/16/shopping-cart-fill.svg'
import Chat from '@zendeskgarden/svg-icons/src/16/speech-bubble-plain-fill.svg'
import Check from '@zendeskgarden/svg-icons/src/16/check-lg-fill.svg'
import CheckBadge from '@zendeskgarden/svg-icons/src/16/check-badge-fill.svg'
import CircleCheckedFill from '@zendeskgarden/svg-icons/src/16/check-circle-fill.svg'
import CircleCheckedStroke from '@zendeskgarden/svg-icons/src/16/check-circle-stroke.svg'
import CircleFill from '@zendeskgarden/svg-icons/src/16/circle-full-fill.svg'
import CircleStroke from '@zendeskgarden/svg-icons/src/16/circle-full-stroke.svg'
import CircleLine from '@zendeskgarden/svg-icons/src/16/circle-line-stroke.svg'
import ClipboardChecked from '@zendeskgarden/svg-icons/src/16/clipboard-check-stroke.svg'
import ClipboardUnChecked from '@zendeskgarden/svg-icons/src/16/clipboard-blank-fill.svg'
import Clock from '@zendeskgarden/svg-icons/src/16/clock-stroke.svg'
import Close from '@zendeskgarden/svg-icons/src/16/x-stroke.svg'
import ClosePanel from '@zendeskgarden/svg-icons/src/16/x-circle-fill.svg'
import Configuration from '@zendeskgarden/svg-icons/src/16/gear-fill.svg'
import ConversationStroke from '@zendeskgarden/svg-icons/src/16/speech-bubble-conversation-stroke.svg'
import ConversationFill from '@zendeskgarden/svg-icons/src/16/speech-bubble-conversation-fill.svg'
import CreditCard from '@zendeskgarden/svg-icons/src/16/credit-card-fill.svg'
import Customer from '@zendeskgarden/svg-icons/src/16/user-solo-fill.svg'
import DetailPanelStroke from '@zendeskgarden/svg-icons/src/16/shopping-cart-stroke.svg'
import Document from '@zendeskgarden/svg-icons/src/16/file-document-fill.svg'
import Duplicate from '@zendeskgarden/svg-icons/src/16/duplicate-stroke.svg'
import DuplicateFill from '@zendeskgarden/svg-icons/src/16/duplicate-fill.svg'
import Undo from '@zendeskgarden/svg-icons/src/16/edit-undo-fill.svg'
import ExitFill from '@zendeskgarden/svg-icons/src/16/exit-fill.svg'
import ExitStroke from '@zendeskgarden/svg-icons/src/16/exit-stroke.svg'
import EyeClosed from '@zendeskgarden/svg-icons/src/16/eye-fill.svg'
import EyeOpened from '@zendeskgarden/svg-icons/src/16/eye-stroke.svg'
import Facebook from '@zendeskgarden/svg-icons/src/16/facebook-fill.svg'
import Filter from '@zendeskgarden/svg-icons/src/16/filter-stroke.svg'
import FlagActive from '@zendeskgarden/svg-icons/src/16/flag-fill.svg'
import FlagInactive from '@zendeskgarden/svg-icons/src/16/flag-stroke.svg'
import Globe from '@zendeskgarden/svg-icons/src/16/globe-stroke.svg'
import GlobeFill from '@zendeskgarden/svg-icons/src/16/globe-fill.svg'
import Grip from '@zendeskgarden/svg-icons/src/16/grip.svg'
import Home from '@zendeskgarden/svg-icons/src/16/home-stroke.svg'
import Info from '@zendeskgarden/svg-icons/src/16/info-stroke.svg'
import LeftArrow from '@zendeskgarden/svg-icons/src/16/chevron-left-fill.svg'
import Lightbulb from '@zendeskgarden/svg-icons/src/16/lightbulb-stroke.svg'
import LightningBolt from '@zendeskgarden/svg-icons/src/16/lightning-bolt-fill.svg'
import LineSocial from '@zendeskgarden/svg-icons/src/16/line-social-fill.svg'
import Link from '@zendeskgarden/svg-icons/src/16/link-stroke.svg'
import LocationPin from '@zendeskgarden/svg-icons/src/16/location-stroke.svg'
import Locked from '@zendeskgarden/svg-icons/src/16/lock-locked-fill.svg'
import MailClosedFill from '@zendeskgarden/svg-icons/src/16/email-fill.svg'
import MobilePhone from '@zendeskgarden/svg-icons/src/16/mobile-phone-fill.svg'
import Move from '@zendeskgarden/svg-icons/src/26/arrow-right-left.svg'
import NewWindow from '@zendeskgarden/svg-icons/src/16/new-window-stroke.svg'
import Note from '@zendeskgarden/svg-icons/src/16/notes-stroke.svg'
import NotificationDisabled from '@zendeskgarden/svg-icons/src/16/notification-stroke.svg'
import NotificationEnabled from '@zendeskgarden/svg-icons/src/16/notification-fill.svg'
import People from '@zendeskgarden/svg-icons/src/16/user-group-stroke.svg'
import Pdf from '@zendeskgarden/svg-icons/src/16/file-pdf-stroke.svg'
import Phone from '@zendeskgarden/svg-icons/src/16/phone-fill.svg'
import Plus from '@zendeskgarden/svg-icons/src/16/plus-circle-stroke.svg'
import Presentation from '@zendeskgarden/svg-icons/src/16/file-presentation-stroke.svg'
import QuestionMark from '@zendeskgarden/svg-icons/src/16/question-mark-fill.svg'
import Reload from '@zendeskgarden/svg-icons/src/16/reload-fill.svg'
import RecurringFill from '@zendeskgarden/svg-icons/src/16/clock-cycle-fill.svg'
import RecurringStroke from '@zendeskgarden/svg-icons/src/16/clock-cycle-stroke.svg'
import RightArrow from '@zendeskgarden/svg-icons/src/16/chevron-right-fill.svg'
import Saved from '@zendeskgarden/svg-icons/src/16/heart-fill.svg'
import SmileSlight from '@zendeskgarden/svg-icons/src/16/smile-slight-stroke.svg'
import SmileSlightFill from '@zendeskgarden/svg-icons/src/16/smile-slight-fill.svg'
import Star from '@zendeskgarden/svg-icons/src/16/star-fill.svg'
import Search from '@zendeskgarden/svg-icons/src/16/search-stroke.svg'
import Settings from '@zendeskgarden/svg-icons/src/16/database-fill.svg'
import Tag from '@zendeskgarden/svg-icons/src/16/tag-stroke.svg'
import ThumbsDown from '@zendeskgarden/svg-icons/src/16/thumbs-down-fill.svg'
import ThumbsUp from '@zendeskgarden/svg-icons/src/16/thumbs-up-fill.svg'
import Ticketing from '@zendeskgarden/svg-icons/src/16/monitor-fill.svg'
import Tickets from '@zendeskgarden/svg-icons/src/26/customer-lists-fill.svg'
import Timeline from '@zendeskgarden/svg-icons/src/16/indent-increase-stroke.svg'
import Twitter from '@zendeskgarden/svg-icons/src/16/twitter-fill.svg'
import TrashFill from '@zendeskgarden/svg-icons/src/16/trash-fill.svg'
import TrashStroke from '@zendeskgarden/svg-icons/src/16/trash-stroke.svg'
import Unlocked from '@zendeskgarden/svg-icons/src/16/lock-unlocked-stroke.svg'
import Unsaved from '@zendeskgarden/svg-icons/src/16/heart-stroke.svg'
import UserList from '@zendeskgarden/svg-icons/src/16/user-list-fill.svg'
import User from '@zendeskgarden/svg-icons/src/16/user-solo-stroke.svg'
import WeChat from '@zendeskgarden/svg-icons/src/16/wechat-fill.svg'
import WhatsApp from '@zendeskgarden/svg-icons/src/16/whatsapp-fill.svg'

export * from './svg'

export const ExitReverseIcon = styled(ExitStroke)`
  transform: scaleX(-1);
`

export const BoldIcon = Bold
export const ItalicIcon = Italic
export const UnderlineIcon = Underline

export const AlertStrokeIcon = AlertStroke
export const AlertFillIcon = AlertFill
export const ArrowChangeIcon = ArrowChange
export const ArrowDownIcon = ArrowDown
export const ArrowLeftIcon = ArrowLeft
export const ArrowLeftUpIcon = ArrowLeftUp
export const ArrowReverseIcon = ArrowReverse
export const ArrowCycleIcon = ArrowCycle
export const ArrowRightIcon = ArrowRight
export const BackArrowIcon = BackArrow
export const BookFillIcon = BookFill
export const BookStrokeIcon = BookStroke
export const BulletsIcon = Bullets
export const CalendarIcon = Calendar
export const ChatIcon = Chat
export const CheckIcon = Check
export const CheckBadgeIcon = CheckBadge
export const CircleCheckedFillIcon = CircleCheckedFill
export const CircleCheckedStrokeIcon = CircleCheckedStroke
export const CircleFillIcon = CircleFill
export const CircleStrokeIcon = CircleStroke
export const CircleLineIcon = CircleLine
export const ClipboardCheckedIcon = ClipboardChecked
export const ClipboardUnCheckedIcon = ClipboardUnChecked
export const ClockIcon = Clock
export const CloseIcon = Close
export const ClosePanelIcon = ClosePanel
export const ConfigurationIcon = Configuration
export const ConversationStrokeIcon = ConversationStroke
export const ConversationFillIcon = ConversationFill
export const CreditCardIcon = CreditCard
export const CustomerIcon = Customer
export const DetailPanelFillIcon = Cart
export const DocumentIcon = Document
export const DuplicateIcon = Duplicate
export const DuplicateFillIcon = DuplicateFill
export const UndoIcon = Undo
export const ExitIcon = ExitFill
export const EyeClosedIcon = EyeClosed
export const EyeOpenedIcon = EyeOpened
export const FacebookIcon = Facebook
export const FeedbackIcon = ConversationFill
export const FilterIcon = Filter
export const FlagActiveIcon = FlagActive
export const FlagInactiveIcon = FlagInactive
export const GlobeIcon = Globe
export const GlobeFillIcon = GlobeFill
export const GripIcon = Grip
export const GuestIcon = User
export const HomeIcon = Home
export const InfoIcon = Info
export const LeftArrowIcon = LeftArrow
export const LineSocialIcon = LineSocial
export const LinkIcon = Link
export const LocationPinIcon = LocationPin
export const LockedIcon = Locked
export const MacroIcon = LightningBolt
export const MailClosedFillIcon = MailClosedFill
export const MobilePhoneIcon = MobilePhone
export const MoveIcon = Move
export const NewWindowIcon = NewWindow
export const NoteIcon = Note
export const NotificationDisabledIcon = NotificationDisabled
export const NotificationEnabledIcon = NotificationEnabled
export const PdfIcon = Pdf
export const PeopleIcon = People
export const PhoneIcon = Phone
export const PlusIcon = Plus
export const PresentationIcon = Presentation
export const SavedIcon = Saved
export const SmileSlightIcon = SmileSlight
export const SmileSlightFillIcon = SmileSlightFill
export const StarIcon = Star
export const QuestionMarkIcon = QuestionMark
export const RecurringFillIcon = RecurringFill
export const RecurringStrokeIcon = RecurringStroke
export const RightArrowIcon = RightArrow
export const SearchIcon = Search
export const SettingsIcon = Settings
export const TagIcon = Tag
export const ThumbsDownIcon = ThumbsDown
export const ThumbsUpIcon = ThumbsUp
export const TicketingIcon = Ticketing
export const TicketsIcon = Tickets
export const TimelineIcon = Timeline
export const TwitterIcon = Twitter
export const TrashFillIcon = TrashFill
export const TrashStrokeIcon = TrashStroke
export const UnlockedIcon = Unlocked
export const UnsavedIcon = Unsaved
export const UserListIcon = UserList
export const UserIcon = User
export const WeChatIcon = WeChat
export const WhatsAppIcon = WhatsApp

export const RichTextIcons = {
  bold: BoldIcon,
  italic: ItalicIcon,
  underline: UnderlineIcon,
}

export const DetailPanelStrokeIcon = styled(DetailPanelStroke)`
  padding-bottom: 1px;
  padding-right: 1px;
`

export const ArrowUpIcon = styled(ArrowDownIcon)`
  transform: rotate(180deg);
`

export const CartIcon = styled(Cart)`
  padding-right: 1px;
`

export const ReloadIcon = styled(Reload)`
  transform: scaleX(-1);
`

export const LightbulbIcon = styled(Lightbulb)`
  margin-top: -1px;
  transform: scale(1.1);
`

export const CopyableIcon = ({ isCopied, ...renderProps }) => {
  if (isCopied) {
    return <ClipboardCheckedIcon {...renderProps} />
  }

  return <ClipboardUnCheckedIcon {...renderProps} />
}
