import React from 'react'
import styled from 'styled-components'

import { lowercase } from '@agnostack/lib-core'
import { useAsyncMemo, ImagePlaceholder } from '@agnostack/lib-utils-react'

// NOTE: change to use React.lazy/Suspense once we move to react 18?
const LAZY_VENDORS = {
  AgnoStack: {
    color: '#682860',
    Logo: async (props) => ((await import('./agnostack.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/agnostack.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/agnostack.svg')).default)(props),
  },
  Amazon: {
    color: '#EC912D',
    Logo: async (props) => ((await import('./amazon.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/amazon.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/amazon.svg')).default)(props),
  },
  AuthorizeNet: {
    color: '#055D77',
    Logo: async (props) => ((await import('./authorizenet.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/authorizenet.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/authorizenet.svg')).default)(props),
  },
  BigCommerce: {
    color: '#34313F',
    Logo: async (props) => ((await import('./bigcommerce.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/bigcommerce.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/bigcommerce.svg')).default)(props),
  },
  Bloomreach: {
    color: '#002840',
    Logo: async (props) => ((await import('./bloomreach.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/bloomreach.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/bloomreach.svg')).default)(props),
  },
  Braintree: {
    color: '#000000',
    Logo: async (props) => ((await import('./braintree.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/braintree.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/braintree.svg')).default)(props),
  },
  CommerceTools: {
    color: '#00B39E',
    Logo: async (props) => ((await import('./commercetools.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/commercetools.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/commercetools.svg')).default)(props),
  },
  Cordial: {
    color: '#FABC1C',
    Logo: async (props) => ((await import('./cordial.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/cordial.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/cordial.svg')).default)(props),
  },
  DotDigital: {
    color: '#4D5357',
    Logo: async (props) => ((await import('./dotdigital.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/dotdigital.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/dotdigital.svg')).default)(props),
  },
  DynamicsBusinessCentral: {
    color: '#047C8B',
    Logo: async (props) => ((await import('./dynamicsbusinesscentral.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/dynamicsbusinesscentral.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/dynamicsbusinesscentral.svg')).default)(props),
  },
  ElasticPath: {
    color: '#2BCC7E',
    Logo: async (props) => ((await import('./elasticpath.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/elasticpath.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/elasticpath.svg')).default)(props),
  },
  Emarsys: {
    color: '#0C2838',
    Logo: async (props) => ((await import('./emarsys.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/emarsys.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/emarsys.svg')).default)(props),
  },
  GooglePalm: {
    color: '#79797A',
    Logo: async (props) => ((await import('./googlepalm.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/googlepalm.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/googlepalm.svg')).default)(props),
  },
  Gmail: {
    color: '#79797A',
    Logo: async (props) => ((await import('./gmail.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/gmail.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/gmail.svg')).default)(props),
  },
  Growave: {
    color: '#013A52',
    Logo: async (props) => ((await import('./growave.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/growave.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/growave.svg')).default)(props),
  },
  HubSpot: {
    color: '#FF7A59',
    Logo: async (props) => ((await import('./hubspot.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/hubspot.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/hubspot.svg')).default)(props),
  },
  InfluenceIO: {
    color: '#828FDB',
    Logo: async (props) => ((await import('./influenceio.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/influenceio.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/influenceio.svg')).default)(props),
  },
  Inveterate: {
    color: '#B27DFF',
    Logo: async (props) => ((await import('./inveterate.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/inveterate.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/inveterate.svg')).default)(props),
  },
  JudgeMe: {
    color: '#339999',
    Logo: async (props) => ((await import('./judgeme.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/judgeme.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/judgeme.svg')).default)(props),
  },
  Klaviyo: {
    color: '#232426',
    Logo: async (props) => ((await import('./klaviyo.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/klaviyo.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/klaviyo.svg')).default)(props),
  },
  LoyaltyLion: {
    color: '#A81BE8',
    Logo: async (props) => ((await import('./loyaltylion.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/loyaltylion.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/loyaltylion.svg')).default)(props),
  },
  LtvAI: {
    color: '#645EF6',
    Logo: async (props) => ((await import('./ltvai.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/ltvai.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/ltvai.svg')).default)(props),
  },
  Magento: {
    color: '#F26322',
    Logo: async (props) => ((await import('./magento.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/magento.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/magento.svg')).default)(props),
  },
  Mailchimp: {
    color: '#FFE11B',
    Logo: async (props) => ((await import('./mailchimp.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/mailchimp.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/mailchimp.svg')).default)(props),
  },
  Narvar: {
    color: '#0095D3',
    Logo: async (props) => ((await import('./narvar.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/narvar.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/narvar.svg')).default)(props),
  },
  Okendo: {
    color: '#00EAB6',
    Logo: async (props) => ((await import('./okendo.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/okendo.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/okendo.svg')).default)(props),
  },
  OpenAI: {
    color: '#080808',
    Logo: async (props) => ((await import('./openai.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/openai.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/openai.svg')).default)(props),
  },
  Ordergroove: {
    color: '#00FF85',
    Logo: async (props) => ((await import('./ordergroove.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/ordergroove.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/ordergroove.svg')).default)(props),
  },
  PayWhirl: {
    color: '#0288D1',
    Logo: async (props) => ((await import('./paywhirl.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/paywhirl.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/paywhirl.svg')).default)(props),
  },
  Postscript: {
    color: '#5724E9',
    Logo: async (props) => ((await import('./postscript.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/postscript.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/postscript.svg')).default)(props),
  },
  Recharge: {
    color: '#3901F1',
    Logo: async (props) => ((await import('./recharge.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/recharge.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/recharge.svg')).default)(props),
  },
  ReferralCandy: {
    color: '#FD108B',
    Logo: async (props) => ((await import('./referralcandy.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/referralcandy.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/referralcandy.svg')).default)(props),
  },
  Rivo: {
    color: '#151A17',
    Logo: async (props) => ((await import('./rivo.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/rivo.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/rivo.svg')).default)(props),
  },
  Salesforce: {
    color: '#00A1E0',
    Logo: async (props) => ((await import('./salesforce.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/salesforce.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/salesforce.svg')).default)(props),
  },
  ShipEngine: {
    color: '#162948',
    Logo: async (props) => ((await import('./shipengine.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/shipengine.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/shipengine.svg')).default)(props),
  },
  ShipperHQ: {
    color: '#3B4456',
    Logo: async (props) => ((await import('./shipperhq.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/shipperhq.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/shipperhq.svg')).default)(props),
  },
  Shippo: {
    color: '#30881E',
    Logo: async (props) => ((await import('./shippo.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/shippo.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/shippo.svg')).default)(props),
  },
  ShipStation: {
    color: '#6CBE45',
    Logo: async (props) => ((await import('./shipstation.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/shipstation.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/shipstation.svg')).default)(props),
  },
  Shopify: {
    color: '#96BF45',
    Logo: async (props) => ((await import('./shopify.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/shopify.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/shopify.svg')).default)(props),
  },
  SimpleSat: {
    color: '#43BE64',
    Logo: async (props) => ((await import('./simplesat.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/simplesat.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/simplesat.svg')).default)(props),
  },
  Smile: {
    color: '#FFC419',
    Logo: async (props) => ((await import('./smile.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/smile.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/smile.svg')).default)(props),
  },
  Stamped: {
    color: '#171838',
    Logo: async (props) => ((await import('./stamped.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/stamped.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/stamped.svg')).default)(props),
  },
  StayAI: {
    color: '#EF0073',
    Logo: async (props) => ((await import('./stayai.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/stayai.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/stayai.svg')).default)(props),
  },
  Stripe: {
    color: '#0A2540',
    Logo: async (props) => ((await import('./stripe.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/stripe.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/stripe.svg')).default)(props),
  },
  TrustPilot: {
    color: '#04DA8D',
    Logo: async (props) => ((await import('./trustpilot.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/trustpilot.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/trustpilot.svg')).default)(props),
  },
  WooCommerce: {
    color: '#96588A',
    Logo: async (props) => ((await import('./woocommerce.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/woocommerce.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/woocommerce.svg')).default)(props),
  },
  Yotpo: {
    color: '#0042E4',
    Logo: async (props) => ((await import('./yotpo.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/yotpo.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/yotpo.svg')).default)(props),
  },
  Zendesk: {
    color: '#04444D',
    Logo: async (props) => ((await import('./zendesk.svg')).default)(props),
    IconColor: async (props) => ((await import('./thumbnail/color/zendesk.svg')).default)(props),
    IconSolid: async (props) => ((await import('./thumbnail/solid/zendesk.svg')).default)(props),
  },
}

const VENDOR_NAMES = Object.keys(LAZY_VENDORS)

export const VENDOR_COLORS = VENDOR_NAMES.reduce((_vendorColors, vendorName) => ({
  ..._vendorColors,
  [lowercase(vendorName)]: LAZY_VENDORS[vendorName].color,
}), {})

const LazyImage = ({
  fallback = (<></>),
  component,
  ...props
}) => {
  const Image = useAsyncMemo(() => (
    component?.(props)
  ), [component])

  if (!Image) {
    return fallback
  }

  return Image
}

const LazyLogo = (props) => (
  <LazyImage fallback={<ImagePlaceholder height="xlarge" with="xxlarge" />} {...props} />
)

const LazyIcon = (props) => (
  <LazyImage fallback={<ImagePlaceholder />} {...props} />
)

export const ProviderLogos = VENDOR_NAMES.reduce((_logos, vendorName) => ({
  ..._logos,
  [lowercase(vendorName)]: (props) => (<LazyLogo component={LAZY_VENDORS[vendorName].Logo} {...props} />),
}), {})

const color = VENDOR_NAMES.reduce((_icons, vendorName) => ({
  ..._icons,
  [lowercase(vendorName)]: (props) => (<LazyIcon component={LAZY_VENDORS[vendorName].IconColor} {...props} />),
}), {})

const solid = VENDOR_NAMES.reduce((_icons, vendorName) => ({
  ..._icons,
  [lowercase(vendorName)]: (props) => (<LazyIcon component={LAZY_VENDORS[vendorName].IconSolid} {...props} />),
}), {})

const white = Object.entries(solid).reduce((_icons, [key, Icon]) => ({
  ..._icons,
  [key]: styled(Icon)`
    ${({ theme: { palette } = {} }) => `
      color: ${palette?.white ?? 'white'};
    `}
  `,
}), {})

export const ProviderIcons = {
  color,
  solid,
  white,
}

export const AgnoStackWordmarkIcon = styled(ProviderLogos.agnostack)`
  color: #2E2C2C;
`

export const AgnoStackIconWhite = styled(ProviderIcons.solid.agnostack)`
  padding: 1px;
  ${({ theme: { palette } = {} }) => `
    color: ${palette?.white ?? 'white'};
  `}
`

export const AgnoStackIcon = styled(ProviderIcons.color.agnostack)`
  padding: 1px;
`
