import styled from 'styled-components'

import AgnoStackListing from './listings/agnoStack.svg'
import BigCommerceListing from './listings/bigcommerce.svg'
import CommerceToolsListing from './listings/commercetools.svg'
import CustomerInsightsListing from './listings/customer_insights.svg'
import Magento2Listing from './listings/magento2.svg'
import ShopifyListing from './listings/shopify.svg'
import ShopifyPremiumListing from './listings/shopify_premium.svg'

import _ShopifyPremiumIllustration from './illustration-shopify-premium.svg'
import _AgnoStackIllustration from './illustration.svg'
import Bank from './paymentmethods/bank.svg'
import CardIcons from './paymentmethods/card'
import Caclulator from './calculator-stroke.svg'
import CatalogFill from './catalog-fill.svg'
import CatalogStroke from './catalog-stroke.svg'
import Collection from './collection-fill.svg'
import DiscountFill from './discount-fill.svg'
import DollarSignFill from './currency-dollar-fill.svg'
import DollarSignStroke from './currency-dollar-stroke.svg'
import GiftLine from './gift-line.svg'
import TagAction from './tag-action-line.svg'
import TruckFill from './truck-fill.svg'
import TruckLine from './truck-line.svg'
import Magic from './magic-fill.svg'
import MailOpenFill from './mail-open-fill.svg'
import MailOpenLine from './mail-open-line.svg'
import News from './newspaper-fill.svg'
import RobotFill from './robot-fill.svg'
import RobotCloudFill from './robot-cloud-fill.svg'

export * from './providers'

export const AgnoStackListingIcon = AgnoStackListing
export const BigCommerceListingIcon = BigCommerceListing
export const CommerceToolsListingIcon = CommerceToolsListing
export const CustomerInsightsListingIcon = CustomerInsightsListing
export const Magento2ListingIcon = Magento2Listing
export const ShopifyListingIcon = ShopifyListing
export const ShopifyPremiumListingIcon = ShopifyPremiumListing

export const BankIcon = Bank
export const CaclulatorIcon = Caclulator
export const CatalogFillIcon = CatalogFill
export const CatalogStrokeIcon = CatalogStroke
export const CollectionIcon = Collection
export const DiscountIcon = DiscountFill
export const DollarSignIcon = DollarSignStroke
export const DollarSignFillIcon = DollarSignFill
export const TruckLineIcon = TruckLine
export const GiftIcon = GiftLine
export const MagicIcon = Magic
export const NewsIcon = News
export const RobotIcon = RobotFill
export const RobotCloudIcon = RobotCloudFill
export const MailOpenFillIcon = MailOpenFill
export const MailOpenLineIcon = MailOpenLine

export const ShopifyPremiumIllustration = _ShopifyPremiumIllustration
export const AgnoStackIllustration = _AgnoStackIllustration

export const TagActionIcon = styled(TagAction)`
  ${({ theme: { colors, palette } = {} }) => `
    fill: ${palette?.[colors?.primaryHue]?.[600] ?? 'blue'};
  `}
`

export const TruckIcon = styled(TruckFill)`
  padding-top: 1px;
`

export const PaymentMethodIcons = {
  acss_debit: BankIcon,
  ach_credit_transfer: BankIcon,
  us_bank_account: BankIcon,
  card: CardIcons,
}

export const ListingIcons = {
  agnostack: AgnoStackListingIcon,
  shopify: ShopifyListingIcon,
  shopify_premium: ShopifyPremiumListingIcon,
  bigcommerce: BigCommerceListingIcon,
  commercetools: CommerceToolsListingIcon,
  customer_insights: CustomerInsightsListingIcon,
  magento2: Magento2ListingIcon,
}
